@import "settings";

// ---------------------------------------------------------
// MAIN CSS
// ---------------------------------------------------------

* {
  box-sizing: border-box;
  user-select: text;
}

*, :focus {
  outline: 0 !important;
}

html {
  background-color: $bg;
  scroll-behavior: smooth;
  transition: background-color .2s;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  margin: 0;
  height: 100vh;
  width: 100%;
  color: $color;
  -webkit-overflow-scrolling: touch;
}

// SCROLLBAR
::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background-color: $color-op-min;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $color;
}

::-webkit-scrollbar-corner {
  background: none;
}

// SCROLLBAR HORIZONTAL
::-webkit-scrollbar:horizontal {
  height: 8px;
}

// USER SELECTION
::selection {
  background-color: $primary;
  color: $bg;
}

// TABLE
table {
  width: 100%;
  background: transparent;
  border-collapse: collapse;
  text-align: left;
}

table th {
  border-bottom: $border;
  color: $accent;
  font-family: $font-accent-family;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

table tr {
  vertical-align: middle;
  border-bottom: none;
}

table.with-even-rows tr:nth-child(even) {
  background-color: $bg-accent;
}

table td, th {
  padding: $padding-small 10px;
  text-align: right;

}

table tr.selected {
  background-color: $accent-op-max !important;
}

// INPUTS
input, textarea {
  background: none;
  border-bottom: 1px solid $color-op-max;
  border-left-style: none;
  border-radius: 0;
  border-right-style: none;
  border-top-style: none;
  color: inherit;
  font-family: $font, Arial, sans-serif;
  font-size: inherit;
  padding: 4px 4px 4px 0;
  text-overflow: ellipsis;
  width: 100%;
}

input:focus {
  outline: none;
}

input.disabled {
  border-bottom: 1px solid $color-op-max;
}

textarea {
  resize: none;
}

// AUTOCOMPLETE
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $color;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

// LINK
.link {
  color: $color-accent;
  cursor: pointer;
  text-decoration: underline;
  transition: color .2s;
  &:hover {
    color: $primary;
  }
}

a {
  @extend .link;
  font-weight: bold;

  &.blend-in {
    color: inherit;
  }
}

// UL
ul {
  margin: 0;
}

// COLOURS FOR FONT
.color {
  color: $color !important;
  svg {
    stroke: $color !important;
  }
}

.primary {
  color: $primary !important;
  svg {
    stroke: $primary !important;
  }
}

.accent {
  color: $accent !important;
  svg {
    stroke: $accent !important;
  }
}

.accent2 {
  color: $accent2 !important;
  svg {
    stroke: $accent2 !important;
  }
}

.accent3 {
  color: $accent3 !important;
  svg {
    stroke: $accent3 !important;
  }
}

.error {
  color: $error !important;
  svg {
    stroke: $error !important;
  }
}

.disabled {
  color: $disabled !important;
  cursor: default !important;
  pointer-events: none !important;
  svg {
    stroke: $disabled !important;
  }
}

// COLORS FOR BG
.bg-color {
  background-color: $color !important;
  color: set-alpha('bg', 1); // do like this because bg is transparent for video overlays
}

.bg-primary {
  background-color: $primary !important;
  color: $bg;
}

.bg-error {
  background-color: $error;
  color: $bg-darken;
}

.bg-accent {
  background-color: $accent !important;
  color: $bg;
}

.bg-accent2 {
  background-color: $accent2;
  color: $bg-darken;
}

.bg-accent3 {
  background-color: $accent3;
  color: $bg-darken;
}

.hide {
  color: transparent;
  display: none;
}

.clickable {
  cursor: pointer !important;
  user-select: none;
}

.no-select {
  pointer-events: none;
  user-select: none;
}

// TITLES

h1 {
  color: inherit;
  font-family: $font-accent-family;
  font-size: 34px;
  line-height: 34px;
  margin: 0;
  width: 100%;
}

h2 {
  color: $primary;
  display: inline;
  font-family: $font-accent-family;
  font-size: 18px;
  margin: 0;
  width: 100%;
}

h3 {
  color: inherit;
  display: inline;
  font-size: $font-size-big;
  font-weight: bold;
  margin: 0;
  padding-right: 2px;
  width: 100%;
}

h4 {
  color: $accent;
  display: inline;
  font-size: 104%;
  margin: 0;
  width: 100%;
}

h5 {
  display: inline;
  font-size: 110%;
  font-weight: bold;
  margin: 0;
  width: 100%;
}

// mat-icon-like class
.icon {
  cursor: pointer;
  height: 24px;
  user-select: none;
  width: 24px;

  svg {
    height: 24px;
    width: 24px;
  }

  &:hover {
    transform: scale(1.1);
  }
}
