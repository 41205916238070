@import "settings";

// ---------------------------------------------------------
// SIMPLE BUTTON
// ---------------------------------------------------------

button {
  background-color: transparent;
  border: 1.5px solid $color;
  color: $color;
  cursor: pointer;
  font-family: $font-accent-family;
  // font-size: 114%;
  font-weight: bold;
  line-height: 20px;
  min-width: 104px;
  outline: none;
  padding: $padding-small $padding-big;
  text-align: center;
  text-transform: lowercase;
  transition: background-color .4s ease-in-out;
  user-select: none;

  &:hover {
    background: set-alpha('color', 0.2);
  }

  &.primary {
    color: $primary;
    border-color: $primary;
    &:hover {
      background: set-alpha('primary', 0.2);
    }
  }

  &.accent {
    color: $accent;
    border-color: $accent;
    &:hover {
      background: set-alpha('accent', 0.2);
    }
  }

  &.accent2 {
    color: $accent2;
    border-color: $accent2;
    &:hover {
      background: set-alpha('accent2', 0.2);
    }
  }

  &.accent3 {
    color: $accent3;
    border-color: $accent3;
    &:hover {
      background: set-alpha('accent3', 0.2);
    }
  }

  &.error {
    color: $error;
    border-color: $error;

    &:hover {
      background: set-alpha('error', 0.2);
    }

  }

  &.bg {
    color: $bg;
    border-color: $bg;

    &:hover {
      background: set-alpha('bg', 0.2);
    }

  }

  &.disabled {
    color: $disabled;
    border-color: $disabled;
    &:hover {
      background: set-alpha('disabled', 0.2);
    }
  }

}
