@use '@angular/material' as mat;
@import "settings";

// ---------------------------------------------------------
// IMPORT MATERIAL PREDEFINED THEME
// ---------------------------------------------------------

@import '../../node_modules/@angular/material/theming';
@include mat.core();

$my-foreground: (
  base: $color,
  divider: $color-op-min,
  dividers: $color-op-min,
  disabled: set-alpha('color', 0.38),
  disabled-button: set-alpha('color', 0.38),
  disabled-text: set-alpha('color', 0.38),
  hint-text: set-alpha('color', 0.38),
  secondary-text: set-alpha('color', 0.54),
  icon: set-alpha('color', 0.54),
  icons: set-alpha('color', 0.54),
  text: set-alpha('color', 0.87),
  slider-min: set-alpha('color', 0.87),
  slider-off: set-alpha('color', 0.26),
  slider-off-active: set-alpha('color', 0.38),
);

$my-primary: (
  50: lighter('primary', 50%),
  100: lighter('primary', 40%),
  200: lighter('primary', 30%),
  300: lighter('primary', 20%),
  400: lighter('primary', 10%),
  500: $primary,
  600: lighter('primary', -10%),
  700: lighter('primary', -20%),
  800: lighter('primary', -30%),
  900: lighter('primary', -40%),
  A100: lighter('primary', 40%),
  A200: lighter('primary', 20%),
  A400: lighter('primary', -20%),
  A700: lighter('primary', -40%),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: white,
  )
);

$my-accent: (
  50: lighter('accent', 50%),
  100: lighter('accent', 40%),
  200: lighter('accent', 30%),
  300: lighter('accent', 20%),
  400: lighter('accent', 10%),
  500: $accent,
  600: lighter('accent', -10%),
  700: lighter('accent', -20%),
  800: lighter('accent', -30%),
  900: lighter('accent', -40%),
  A100: lighter('accent', 40%),
  A200: lighter('accent', 20%),
  A400: lighter('accent', -20%),
  A700: lighter('accent', -40%),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: white,
  )
);

$my-error: (
  50: lighter('error', 50%),
  100: lighter('error', 40%),
  200: lighter('error', 30%),
  300: lighter('error', 20%),
  400: lighter('error', 10%),
  500: $error,
  600: lighter('error', -10%),
  700: lighter('error', -20%),
  800: lighter('error', -30%),
  900: lighter('error', -40%),
  A100: lighter('error', 40%),
  A200: lighter('error', 20%),
  A400: lighter('error', -20%),
  A700: lighter('error', -40%),
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: white,
  )
);

$my-app-primary: mat.define-palette($my-primary);
$my-app-accent:  mat.define-palette($my-accent);
$my-app-warn:    mat.define-palette($my-error);

// CREATE THE THEME OBJECT (A SASS MAP CONTAINING ALL THE PALETTES)
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
// THEN MERGE FOREGROUND INTO IT
$my-app-theme-custom: map-merge($my-app-theme, (foreground: $my-foreground));
@include mat.all-component-themes($my-app-theme-custom);

// CHANGE FONT GENERALLY
$font-family-quoted: "" + $font-family;
$custom-typography: mat.define-typography-config($font-family-quoted);
@include mat.all-component-typographies($custom-typography);

// ---------------------------------------------------------
// EXTENDS & OVERRIDES
// ---------------------------------------------------------

// ALL MATERIAL COMPONENTS THAT REQUIRE POP-UPS SHOULD ALWAYS BE IN FRONT OF EVERYTHING
.cdk-overlay-container {
  z-index: 9999;
}

// MAT INPUT
input.mat-input-element {
  line-height: 1;
}

textarea.mat-input-element {
  min-height: 24px;
}

.mat-form-field {
  width: 100%;

  &__icons {
    display: flex;
    position: absolute;
    right: $padding-small;
    top: 0;
    > div {
      cursor: pointer;
      height: 24px;
      margin: 0 4px;
      width: 24px;
    }
  }

}

.mat-form-field-label {
  font-weight: bold !important;
  font-size: $font-size !important;
  transform: translateY(-1.34375em) scale(0.85) !important;
}

.mat-input-placeholder {
  text-transform: capitalize;
}

// MAT SELECT AND AUTOCOMPLETE
.mat-select-panel, .mat-select-content {
  background: $bg;
  border-radius: 0 !important;
  border: $border;
  box-shadow: none !important;
}

.mat-autocomplete-panel {
  background: $bg;
}

.mat-option {
  color: $color !important;
  font-size: $font-size;
  height: 34px !important;
  line-height: 34px !important;
}

.mat-option:hover {
  background: $accent !important;
}

.mat-select-value {
  color: inherit !important;
}

// TO BE ABLE TO ADD A TOP OPTION WITHOUT CHECKBOX, FOR STUFF LIKE SELECT ALL
.filter-option {
  border-bottom: $border;
}

.filter-option > .mat-pseudo-checkbox {
  display: none !important;
}

.filter-option > .mat-option-text > .mat-checkbox {
  padding-left: 0 !important;
}

// TOOLTIP

.mat-tooltip {
  background-color: $accent;
  color: $bg !important;
  font-size: 80%;
  font-weight: bold;
  line-height: 20px;
  padding: 4px;
}

// MAT ICON
.mat-icon {
  cursor: default;

  @media #{$desktop} {
    font-size: $font-size-icon !important;
    height: $font-size-icon !important;
    line-height: $font-size-icon !important;
    width: $font-size-icon !important;
  }

  @media #{$tablet-portrait}, #{$tablet-landscape}, #{$phone-portrait}, #{$phone-landscape} {
    font-size: $font-size-icon - 4px !important;
    height: $font-size-icon - 4px !important;
    line-height: $font-size-icon - 4px !important;
    width: $font-size-icon - 4px !important;
  }

  &.small {
    font-size: $font-size-icon - 6px !important;
    height: $font-size-icon - 6px !important;
    line-height: $font-size-icon - 6px !important;
    width: $font-size-icon - 6px !important;
  }

}

// MAT DATE-PICKER

.mat-datepicker-content {
  box-shadow: 0 5px 5px -3px set-alpha('color', .2), 0 8px 10px 1px set-alpha('color', .14), 0 3px 14px 2px set-alpha('color', .12);
  background-color: $bg;
  border: 1px solid $primary;
  border-top: none;
}

table.mat-calendar-table th {
  border: none;
}

table.mat-calendar-table tr {
  height: initial;
}

table.mat-calendar-table tr:nth-child(even) {
  background: none;
}

table.mat-calendar-table td {
  border: none;
}

// MAT PROGRESS BAR
.mat-progress-bar {
  height: 2px !important;
}

// SNACKBAR
.mat-snack-bar-container {
  text-align: justify;
  word-break: break-word;
}

.mat-simple-snackbar-action {
  background-color: $bg;
  border-radius: $border-radius;
  color: $primary;
}

// MAT SNACKBAR CUSTOM CLASS (DO NOT JUST CHANGE THE NAME!!)
.mat-sb-multiline {
  background-color: $bg-accent;
  color: $accent;
  font-weight: bold;
  white-space: pre-line;
  span {
    text-align: center !important;
    width: 100%;
  }
  .mat-button {
    background-color: $accent;
    color: $bg-accent;
    font-weight: bold;
  }
}

// SLIDER
.mat-slider {
  width: 100%;
}
/* this styling is specifically for the controls, move it there when the time comes
.mat-slider {
  height: 48px;
  width: 100%;
}

.mat-slider-horizontal .mat-slider-wrapper {
  top: 40px !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label {
  border-radius: 50% 50% 0 !important;
  transform: rotate(45deg) !important;
}

.mat-slider-disabled .mat-slider-thumb-label {
  display: flex !important;
  background-color: $disabled;
}

.mat-slider-thumb-label-text {
  color: $bg !important;
  font-size: 10px !important;
  font-weight: bold;
  opacity: 1 !important;
}
*/

// RADIO BUTTON
.mat-radio-label-content {
  white-space: break-spaces;
}

// DRAG AND DROP
.cdk-drag-preview {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: $primary;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draggable {
  cursor: move;
}

.drop-box {
  display: flex;
  flex-flow: column;
  min-height: 84px;
  width: 48%;

  h3 {
    background-color: $bg-accent;
    padding-left: $padding-small;
  }

  &__list {
    border: $border-width solid $bg;
    height: 100%;
    max-height: 204px;
    overflow-y: auto;

    > div {
      background-color: $bg;
      border: $border;
      margin-top: $padding-small;
      padding: $padding-small $padding-big;

      &:hover {
        background-color: $bg-accent-small;
      }
    }
  }

}
