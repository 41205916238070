@import "settings";

$bs-row-bg: set-alpha('bg', .8);
$bs-even-row-bg: set-alpha('bg', .94);
.overlay {

  gridster-item {
    border: none;
    height: 100vh !important;
  }

  // messages list
  .msg {
    background-color: $bs-row-bg;

    &:nth-of-type(even) {
      background-color: $bs-even-row-bg;
    }
  }

}
