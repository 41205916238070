@import "settings";

// SWIPER CSS & CUSTOMISATION

@import '~swiper/dist/css/swiper.min.css';

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullets {
  right: 0;
}

.swiper-pagination-bullet {
  background: $color;
  border: none !important;
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullet-active {
  background: $primary !important;
}
