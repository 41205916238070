@import "settings";

.free, .serverCommunity {
  border-color: lightgreen;
  color: lightgreen;
}

.lite {
  border-color: #e3e31a;
  color: #e3e31a;
}

.pro, .pro-plus {
  border-color: darkorange;
  color: darkorange;
}

.serverPartner {
  border-color: red;
  color: red;
}

.price-tag {
  display: inline-block;
  font-family: $font-family;
  min-width: 40px;
  position: relative;

  &:before {
    border-radius: 4px;
    color: $bg;
    font-size: $font-size-small;
    font-weight: bold;
    left: 4px;
    line-height: $font-size-small;
    padding: 0 4px;
    position: absolute;
    bottom: 2px;
  }

  &.lite {
    &:before {
      background-color: #e3e31a;
      content: 'LITE';
    }
  }

  &.pro {
    &:before {
      background-color: darkorange;
      content: 'PRO';
    }
  }

}
