@import "settings";

// FLASH
@keyframes flash {
  0%, 100% {
    box-shadow: none;
  }
  10% {
    box-shadow: 0 0 2px 2px;
  }
}

@keyframes flash-green {
  0%, 100% {
    box-shadow: none;
  }
  10% {
    box-shadow: 0 0 14px 14px $primary;
  }
}

@keyframes txt-flash-fade {
  0% {
    opacity: 0;
    text-shadow: 2px 2px 0 black;
  }
  40% {
    opacity: 1;
    text-shadow: 2px 2px 0 black, 1px 1px 40px;
  }
  100% {
    opacity: 0;
    text-shadow: 2px 2px 0 black;
  }
}


// HOVER
.hover {
  &:hover {
    animation: blink-2 0.9s both;
  }
}

@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

// FADE IN
.fade__in {
  opacity: 0;
  animation: fade-in 1s cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
}

.fade__in-fast {
  opacity: 0;
  animation: fade-in .4s cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// SLIDE IN
.slide-in__left {
  animation: slide-in-right .4s cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
  transform: translateX(-100%);
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.slide-in__right {
  animation: slide-in-right .4s cubic-bezier(0.550, 0.055, 0.675, 0.190) forwards;
  transform: translateX(100%);
}

.slide-in__right__back {
  @extend .slide-in__right;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

// ken burns
@keyframes kenburns-bottom {
  0% {
    transform: scale(1) translate(0, 0);
    transform-origin: 14% 14%;
  }
  100% {
    transform: scale(1.04) translate(-14px, -4px);
    transform-origin: top left;
  }
}
