@import "settings";

// GRIDSTER

gridster, gridster-item {
  background: transparent !important;
}

gridster-item {
  border: $border;
  border-radius: $border-radius;
  overflow: initial !important; // for icons going outside of gridster-item

  &.with-padding {
    padding: $padding-big;
  }

  // show widget icons on hover
  &:hover {
    .widget__icons {
      animation: fade-in .4s ease;
      display: flex;
      justify-content: center;
    }
  }

}

gridster-item .gridster-item-resizable-handler {
  z-index: $grid-resize-zindex;
}

gridster-item .gridster-item-resizable-handler.handle-se {
  border-width: 0 0 $padding-big $padding-big;

  @media #{$tablet-portrait}, #{$tablet-landscape}, #{$phone-portrait}, #{$phone-landscape} {
    border-width: 0 0 24px 24px;
  }

}

gridster-item .gridster-item-resizable-handler.handle-sw {
  height: $padding-big;
  width: $padding-big;

  @media #{$tablet-portrait}, #{$tablet-landscape}, #{$phone-portrait}, #{$phone-landscape} {
    height: 24px !important;
    width: 24px !important;
  }

}

gridster-item:hover .gridster-item-resizable-handler.handle-se {
  border-color: transparent transparent $accent !important;
}

gridster .gridster-preview{
  background: $accent-op-max;
  border-radius: $border-radius;
}

gridster .gridster-column{
  border-left: 1px solid $accent-op-max !important;
  border-right: 1px solid $accent-op-max !important;
}

gridster .gridster-row{
  border-top: 1px solid $accent-op-max !important;
  border-bottom: 1px solid $accent-op-max !important;
}

gridster.mobile {
  // overflow-y: hidden !important;
}

// IFRAME HANDLING INSIDE GRIDSTER ITEM
gridster-item .iframe-wrapper {
  pointer-events: initial;
}

.gridster-item-moving .iframe-wrapper {
  pointer-events: none;
}

.gridster-item-resizing .iframe-wrapper {
  pointer-events: none;
}

// give the widgets a bit of background while resizing
.display-grid > gridster-item {
  background-color: $bg-op-min !important;
}
