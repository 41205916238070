@import "settings";

// WIDTH
.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

// TEXT
.txt {
  line-height: 24px;
  text-align: justify;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.txt-subscript {
  font-size: 80%;
}

.txt-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt-bold {
  font-weight: bold;
}

.txt-ultra {
  font-family: $font-accent-family;
  font-size: $font-size-ultra;
}

.txt-accent {
  font-family: $font-accent-family;
  font-size: 90%;
}

// FLEX
.flex {
  display: flex;

  &.wrap {
    flex-flow: wrap;
  }

  &.wrap-reverse {
    flex-flow: wrap-reverse;
  }

  &.col {
    flex-flow: column;
  }

  &.col-reverse {
    flex-flow: column-reverse;
  }

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.row-reverse {
    flex-flow: row-reverse;
  }

  &.baseline {
    align-items: baseline;
  }

  &.items-center {
    align-items: center;
  }

  .grow-h {
    flex-grow: 1;
    width: 1%;
  }

  .grow-v {
    flex-grow: 1;
    height: 1%;
  }

  @mixin column-gap-x {
    @for $i from 1 through 10 {
      &.column-gap-#{$i} {
        column-gap: #{$i}px;
      }
    }
  }

  @include column-gap-x;

  @mixin row-gap-x {
    @for $i from 1 through 10 {
      &.row-gap-#{$i} {
        row-gap: #{$i}px;
      }
    }
  }

  @include row-gap-x;

}

.flex-1 {
  flex: 1 1 0%;
}
// STICKY
.vert-sticky {
  background-color: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
}

.hor-sticky {
  background-color: inherit;
  position: sticky;
  left: 0;
  z-index: 1;
}

// INTRO HEADER USED IN MANY PAGES
.intro-box {
  background-color: $bg-accent;
  border-radius: $border-radius;
  line-height: 24px;
  margin: $padding-big 0;
  padding: $padding-big;
  svg {
    margin: -4px 0;
    width: 44px;
  }

  &.darker {
    background-color: $bg;
  }

}

// PADDING
.pad-big {
  padding: $padding-big;
}

.pad-big-top {
  padding-top: $padding-big !important;
}

.pad-big-bottom {
  padding-bottom: $padding-big;
}

.pad-big-left {
  padding-left: $padding-big;
}

.pad-big-right {
  padding-right: $padding-big;
}

.pad-small {
  padding: $padding-small;
}

.pad-small-top {
  padding-top: $padding-small;
}

.pad-small-bottom {
  padding-bottom: $padding-small;
}

.pad-small-left {
  padding-left: $padding-small;
}

.pad-small-right {
  padding-right: $padding-small;
}

// NO CARS SELECTED
.no-data {
  background-color: $bg-op-min;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: $padding-big;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}
