@import "settings";

.time-table {
  height: 100%;
  overflow: auto;
  position: relative;
  width: 100%;

  &.striped {
    .row:nth-of-type(even) {
      > .cell {
        background-color: $bg-accent !important;
      }
    }
  }

  &.with-vs {
    display: flex;
    flex-flow: column;
    overflow: hidden; // VIRTUAL SCROLLER WILL DO THE SCROLLING
  }

  .row {
    display: flex;
    position: relative;
    width: 100%;

    // separate even class for tables in which rows are absolute positioned
    &.even {
      > .cell { // put it on cell, not row, for row header-cells
        background-color: $bg-accent !important;
      }
    }

    &:last-of-type {
      border-bottom: $border;
    }

  }

  .header {
    @extend .row;
    background-color: $bg;
    border-bottom: $border;
    color: lighter('color', -6%);
    font-weight: bold;
    min-width: 100%;
    position: sticky;
    top: 0;
    width: fit-content;
    z-index: 4;

    > .cell {

      &.multiline {
        padding-top: 4px;
        white-space: normal;
      }

      .col-resizer {
        border-left: 1px solid $disabled;
        border-right: 1px solid $disabled;
        cursor: ew-resize;
        height: 14px;
        right: 2px;
        width: 4px;
        top: 12px;
        position: absolute;
        transition: height .4s;
        z-index: 5;
      }

    }

  }

  // CELL
  .cell {
    flex-grow: 1;
    flex-shrink: 0;
    height: $table-row-height;
    overflow: hidden;
    padding: 12px 7px 0 7px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: $table-cell-width;

    // GENERAL SUBCLASSES FOR SIZE AND COLOR
    &.separator-left {
      border-left: $border;
    }

    &.separator-right {
      border-right: $border;
    }

    &.tiny {
      flex-grow: 0;
      width: $table-cell-width-tiny;
    }

    &.small {
      flex-grow: 0;
      width: $table-cell-width-small;
    }

    &.medium {
      flex-grow: 0;
      width: $table-cell-width-medium;
    }

    &.large {
      flex-shrink: 0;
      width: $table-cell-width-large;
    }

    // SPECIFIC SUBCLASS FOR CAR ID
    &.car-id {
      border-bottom-style: solid;
      border-bottom-width: 4px;
    }

    // SPECIFIC SUBCLASS FOR SECTORTIME COLOUR
    > .st-colour {
      animation: flash 1s ease-in-out both;
      background-color: $green;
      top: ($table-row-height * 0.5) - 12px;
      color: $green; // COLOR IS NECESSARY FOR THE BOX-SHADOW ANIMATION
      height: 4px;
      left: calc(50% - 22px);
      position: absolute;
      width: 44px;

      &.invalid {
        background-color: #FF0000;
        color: #FF0000; // COLOR IS NECESSARY FOR THE BOX-SHADOW ANIMATION
      }

      &.purple {
        background-color: $purple;
        color: $purple; // COLOR IS NECESSARY FOR THE BOX-SHADOW ANIMATION
      }

    }

    // SPECIFIC SUBCLASSES FOR LAP TIME SUPER & SUBSCRIPT
    .lt-sub, .lt-sup {
      color: $color-op-max;
      font-size: 12px;
      height: 12px;
      left: 0;
      line-height: 12px;
      position: absolute;
      width: 100%;
    }

    .lt-sub {
      bottom: ($table-row-height * 0.5) - 18px;
    }

    .lt-sup {
      top: ($table-row-height * 0.5) - 18px;
    }

    // SPECIFIC SUBCLASS FOR IN PIT
    > div.in-pit {
      background-color: $color !important;
      border-radius: 4px;
      color: set-alpha('bg', 1); // do like this because bg is transparent for video overlays
      font-weight: bold;
    }

  }

}
