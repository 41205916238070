@import "settings";

// GLOBAL WIDGETS CLASS WITH ATTRIBUTES FOR ALL FLAVOURS

.widget {
  height: 100%;
  position: relative;
  width: 100%;

  &.tv {
    background-color: #000000;
  }

  &.with-padding {
    padding: 10px 0 0 10px; // padding = gridster resize handle sizes and location
  }

  &.with-padding-bottom {
    padding-bottom: $padding-big;
  }

  .pro-tip {
    background-color: $accent;
    // border-radius: 14px;
    color: $bg;
    font-family: $font-accent-family;
    margin: $padding-big auto;
    padding: 4px 0;
    text-align: center;
    width: 114px;
    &:before {
      content: 'PRO TIP';

    }
  }

}
