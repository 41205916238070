@import "mixins";

//----------------------------
// GENERAL SETTINGS
//----------------------------

// FONT
$font: var(--font);
$font-family: $font, Arial, sans-serif;
$font-accent: Akira;
$font-accent-family: $font-accent, $font, Arial, sans-serif;
$font-size-small: 12px;
$font-size: 14px;
$font-size-big: 16px;
$font-size-ultra: clamp(44px, 4vw, 54px);
$font-size-icon: 24px;

// SIZES AND INDICES
$border-width: 2px;
$gutter: 44px;
$scrollbar-width: 0px;
$slider-height: 34px;
$table-row-height: 40px;
$table-cell-width: 74px;
$table-cell-width-tiny: 28px;
$table-cell-width-small: 44px;
$table-cell-width-medium: 64px;
$table-cell-width-large: 94px;
$mm-z-index: 9998;
$grid-resize-zindex: 500;
$desktop-max-width: 1400px;
$desktop-max-width-small: 1080px;
$wrap-block-min-width: 254px;

// SPECIFIC OVERLAY VARIABLES
$overlay-margin: 2px;
$overlay-bg-op: 0.9;

// COLOURS ARE STORED IN THEME.MODEL, CHANGE THEM THERE
// THIS HERE IS JUST TO HAVE EASY ACCESSIBLE VARIABLES IN CSS
$bg: get-color('bg', var(--bg-op));
$bg-op-max: get-color('bg', var(--bg-op-max));
$bg-op-min: get-color('bg', var(--bg-op-min));
$bg-overlay: get-color('bg', var(--bg-op-overlay));
$bg-accent: lighter('bg', var(--bg-accent));
$bg-accent-small: lighter('bg', var(--bg-accent-small));
$bg-darken: lighter('bg', var(--bg-darken));

$color: get-color('color');
$color-accent: lighter('color', var(--color-accent));
$color-op-max: get-color('color', var(--color-op-max));
$color-op-min: get-color('color', var(--color-op-min));

$primary: get-color('primary');
$primary-op-max: get-color('primary', var(--primary-op-max));
$primary-op-min: get-color('primary', var(--primary-op-min));
$accent: get-color('accent');
$accent-op-max: get-color('accent', var(--accent-op-max));
$accent-op-min: get-color('accent', var(--accent-op-min));
$accent2: get-color('accent2');
$accent2-op-max: get-color('accent2', var(--accent2-op-max));
$accent2-op-min: get-color('accent2', var(--accent2-op-min));
$accent3: get-color('accent3');
$accent3-op-max: get-color('accent3', var(--accent3-op-max));
$accent3-op-min: get-color('accent3', var(--accent3-op-min));
$error: get-color('error');
$error-op-max: get-color('error', var(--error-op-max));
$error-op-min: get-color('error', var(--error-op-min));
$disabled: get-color('disabled');
$disabled-op-max: get-color('disabled', var(--disabled-op-max));
$disabled-op-min: get-color('disabled', var(--disabled-op-min));

$green: #20df20;
$purple: #da00b4;

// BORDERS, MARGINS AND PADDINGS
$border-radius: 4px;
$border: $border-width solid $bg-accent;
$padding-big: 14px;
$padding-small: $padding-big * 0.5;

// MEDIA QUERIES
$desktop: "screen and (min-width: 1025px)";
$tablet-portrait: "screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)";
$tablet-landscape: "screen and (min-width: 845px) and (max-width: 1024px) and (orientation: landscape)";
$phone-portrait: "screen and (max-width: 767px) and (orientation: portrait)";
$phone-landscape: "screen and (max-width: 844px) and (orientation: landscape)";
