@import "settings";

$tgl-width: 28px;
$tgl-btn-width: 10px;
$tgl-btn-offset: 2px;
$tgl-margin-right: 4px;
.tgl {
  display: none;

  + .tgl-btn {
    cursor: pointer;
    display: inline-block;
    height: $tgl-width * 0.5;
    margin-right: $tgl-margin-right;
    outline: 0;
    position: relative;
    user-select: none;
    width: $tgl-width;

    &:after {
      position: absolute;
      content: "";
      left: $tgl-btn-offset;
      width: $tgl-btn-width;
      height: $tgl-btn-width;
    }

  }

}

.tgl-flat {
  + .tgl-btn {
    background-color: set-alpha('color', 0.4);
    border-radius: $tgl-width * 0.5;
    margin-bottom: -4px;
    padding: 2px;
    transition: all .2s ease;

    &:after {
      background: $color;
      border-radius: 50%;
      text-align: center;
      transition: all .2s ease-out;
      &.disabled {
        background-color: set-alpha('color', 0.2);
      }
    }

  }

  &:checked + .tgl-btn {
    background-color: $primary-op-max;
    &:after {
      background-color: $primary;
      left: $tgl-width - $tgl-btn-width - $tgl-btn-offset;
    }
  }

  &.disabled + .tgl-btn {
    background-color: set-alpha('color', 0.1);
    cursor: default !important;
    &:after {
      background-color: set-alpha('color', 0.4);
    }
  }
}

.tgl-subtxt {
  color: $color-op-min;
  font-size: 90%;
  line-height: $font-size * 1.2;
  padding: $padding-small ($tgl-width + $tgl-margin-right);
}
