@import "settings";
@import "../../node_modules/flag-icons/css/flag-icons.min.css";

// ---------------------------------------------------------
// FLAGS
// ---------------------------------------------------------

// COLOUR AS BACKGROUND
.G-flag {
  background-color: $green !important;
  color: $bg !important;
}

.Y-flag {
  background-color: #FFE61D !important;
  color: $bg !important;
}

.X-flag {
  background-color: $purple !important;
}

.R-flag, .I-flag {
  background-color: #FF0000 !important;
}

.S-flag {
  background-color: #9D9D9D !important;
  color: $bg !important;
}

.C-flag {
  background-color: #FFFFFF !important;
  color: $bg !important;
}

// COLOUR AS FONT COLOR
.G-flag-txt {
  color: $green !important;
  stroke: $green !important;
}

.Y-flag-txt {
  color: #FFE61D !important;
  stroke: #FFE61D !important;
}

.X-flag-txt {
  color: $purple !important;
  stroke: $purple !important;
}

.R-flag-txt, .I-flag-txt {
  color: #FF0000 !important;
  stroke: #FF0000 !important;
}

.S-flag-txt {
  color: #9D9D9D !important;
  stroke: #9D9D9D !important;
}

.C-flag-txt {
  color: #FFFFFF !important;
  stroke: #FFFFFF !important;
}
