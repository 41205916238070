@import "settings";

// ---------------------------------------------------------
// HOST FONT FILES LOCALLY (NOT ALWAYS INTERNET ACCESS)
// ---------------------------------------------------------

// MAIN FONT
@font-face {
  font-display: swap;
  font-family: Play;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Play-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: Play;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Play-Bold.ttf") format("truetype");
}

// ACCENT FONT
@font-face {
  font-display: swap;
  font-family: Akira;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Akira.ttf") format("truetype");
}

// PARTNER FONTS
// SRO

@font-face {
  font-display: swap;
  font-family: Exo2;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Exo2-Medium.otf") format("opentype");
}

// MATERIAL ICONS
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"),
  local("MaterialIcons-Regular"),
  url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 0.8;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  cursor: pointer;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // SUPPORT FOR WEBKIT BROWSERS
  -webkit-font-smoothing: antialiased;
  // SUPPORT FOR SAFARI AND CHROME
  text-rendering: optimizeLegibility;

  // SUPPORT FOR FIREFOX
  -moz-osx-font-smoothing: grayscale;

  // SUPPORT FOR IE
  font-feature-settings: "liga";

  @media #{$desktop} {
    font-size: $font-size-icon;
  }

  @media #{$tablet-portrait}, #{$tablet-landscape}, #{$phone-portrait}, #{$phone-landscape} {
    font-size: $font-size-icon;
  }

}
